import { VERSION_URL } from "../utils/url";
import { tryCatch } from "@thalesrc/js-utils";
import http from "./http";

export async function getVersion() {
  const url = VERSION_URL;

  const [error, result] = await tryCatch(http.get(url));

  if (error) throw error;

  return result.data.data;
}
