/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { tryCatch } from "@thalesrc/js-utils";
import { useFormik } from "formik";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { changePasswordReset } from "../service/auth";
import { LoadingContext } from "../context/Loading";
import { ToasterContext } from "../context/ToasterContext";
import { useHistory } from "react-router-dom";
import pageURL from "../utils/pageUrls";
import { useTranslation } from "react-i18next";
import CustomForm from "../components/CustomForm";
import * as Yup from "yup";
import classNames from "classnames";

export default function ChangePasswordForget() {
  let history = useHistory();
  const { openToaster } = useContext(ToasterContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { t } = useTranslation();

  const header = <h6>{t("validation.suggestions.passwordLevel")}</h6>;
  const footer = (
    <>
      <Divider />
      <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
        <li>{t("validation.suggestions.atLeast1LowerCaseLetter")}</li>
        <li>{t("validation.suggestions.atLeast1CapitalCaseLetter")}</li>
        <li>{t("validation.suggestions.atLeast1Digit")}</li>
        <li>{t("validation.suggestions.mustBeAtLeast8Characters")}</li>
      </ul>
    </>
  );

  const passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})", "g");

  const validationSchema = Yup.object({
    password: Yup.string().required(t("validation.required", "Zorunlu Alan")).matches(passRegex, t("validation.checkTerms")),
    confirmPassword: Yup.string()
      .required(t("validation.required", "Zorunlu Alan"))
      .oneOf([Yup.ref("password"), null], t("validation.notMatchPassword")),
  });

  const formik = useFormik({
    initialValues: {
      token: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (formData) => {
      showLoading();

      const [err, res] = await tryCatch(changePasswordReset(formData));

      if (err) {
        hideLoading();
        openToaster("bottom-right", { severity: "error", summary: err?.response?.data?.externalResponseCodeName, detail: err?.response?.data?.message, life: 3000 });
        return;
      }

      hideLoading();
      openToaster("bottom-right", { severity: "success", summary: res?.httpStatusCodeName, detail: res?.responseCodeName, life: 3000 });
      history.push(pageURL.login);
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let token = params.get("token");

    formik.setFieldValue("token", token);
  }, []);

  const formItems = [
    {
      label: t("general.newPassword", "password"),
      name: "password",
      component: "password",
    },
    {
      label: t("general.newPassword", "ConfirmPassword"),
      name: "confirmPassword",
      component: "password",
      header: header,
      footer: footer,
      feedback: true,
    },
  ];

  const containerClassName = classNames("surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden login-page");

  return (
    <div className={containerClassName}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
        <img src={`/images/experilabsDark.svg`} alt="expsrc" className="mb-5 w-20rem flex-shrink-0" />
            <div className="text-center mb-5">
              <div className="text-900 text-2xl font-medium mb-3">{t("general.changePassword")}</div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <CustomForm items={formItems} data={formik} handleChange={formik.handleChange} isColumn />
              <Button label={t("general.save")} className="p-button-raised w-full mt-3" type="submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
