/* eslint-disable no-useless-escape */
import React, { useContext } from "react";
import { Button } from "primereact/button";
import { forgetPassword } from "../service/auth";
import { ToasterContext } from "../context/ToasterContext";
import { LoadingContext } from "../context/Loading";
import { useTranslation } from "react-i18next";
import pageURL from "../utils/pageUrls.js";
import { useHistory } from "react-router";
import CustomForm from "../components/CustomForm.js";
import { useFormik } from "formik";
import { tryCatch } from "@thalesrc/js-utils";
import * as Yup from "yup";
import { classNames } from "primereact/utils";

function ForgetPassword() {
  const { t } = useTranslation();
  const { openToaster } = useContext(ToasterContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { push } = useHistory();

  const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const validationSchema = Yup.object({
    email: Yup.string().required(t("validation.required", "Zorunlu Alan")).email(t("validation.mailInvalid")).matches(mailRegex, t("validation.mailInvalid")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (formData) => {
      showLoading();
      const [err, res] = await tryCatch(forgetPassword(formData));

      if (err) {
        hideLoading();
        openToaster("bottom-right", { severity: "error", summary: err?.httpStatusCode, detail: err.message, life: 3000 });
        return;
      }

      hideLoading();
      openToaster("bottom-right", { severity: "success", summary: res?.httpStatusCodeName, detail: res?.responseCodeName, life: 3000 });
      push(pageURL.home);
    },
  });

  const formItems = [
    {
      label: t("general.email", "email"),
      name: "email",
      component: "input",
    },
  ];

  const containerClassName = classNames("surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden login-page");

  return (
    <div className={containerClassName}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
        <img src={`/images/experilabsDark.svg`} alt="expsrc" className="mb-5 w-20rem flex-shrink-0" />
            <div className="text-center mb-5">
              <div className="text-900 text-2xl font-medium mb-3">{t("general.getNewPassword")}</div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <CustomForm items={formItems} data={formik} handleChange={formik.handleChange} isColumn />
              <Button label={t("general.send")} type="submit" className="p-button-raised mt-3 w-full" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
