import { tryCatch } from "@thalesrc/js-utils";
import { LOGIN_URL, GET_CUSTOMER_URL, GENERATE_PASSWORD, FORGET_PASSWORD, GENERATE_PASSWORD_FORGET, CHANGE_PASSWORD } from "../utils/url";
import http from "./http";

export async function loginUser(payload) {
  const url = LOGIN_URL;

  const [error, result] = await tryCatch(http.post(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function generatePassword(payload) {
  const url = GENERATE_PASSWORD;

  const [error, result] = await tryCatch(http.put(url, payload));

  if (error) throw error;

  return result.data;
}

export async function forgetPassword(payload) {
  const url = FORGET_PASSWORD;

  const [error, result] = await tryCatch(http.post(url, payload));

  if (error) throw error;

  return result.data;
}

export async function changePasswordReset(payload) {
  const url = GENERATE_PASSWORD_FORGET;

  const [error, result] = await tryCatch(http.put(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function changePassword(payload) {
  const url = CHANGE_PASSWORD;

  const [error, result] = await tryCatch(http.put(url, payload));

  if (error) throw error;

  return result.data;
}

export function getCustomer() {
  return new Promise((resolve, reject) => {
    http
      .get(GET_CUSTOMER_URL)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}
