/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";

import { AppTopbar } from "./AppTopbar";
import { AppMenu } from "./AppMenu";
import TheContent from "./TheContent";
import pageURL from "../utils/pageUrls";
import { AppContext } from "../context/AppContext";
import { UserContext } from "../context/UserContext";
import { Authority } from "../utils/authority";
import { getVersion } from "../service/version";
import { useFetch } from "../hooks/use-fetch";
import packageJson from "../../package.json";
import Loader from "../components/Loader";

export default function TheLayout() {
  const [layoutMode] = useState("static");
  const [layoutColorMode] = useState("light");
  const [sideMenu, setSideMenu] = useState([]);
  const [inputStyle] = useState("outlined");
  const [ripple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const { theme } = useContext(AppContext);
  const { user } = useContext(UserContext);

  const [version] = useFetch(() => getVersion(), [], { reloadDeps: [], deps: [] });

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    const themeHref = `${process.env.REACT_APP_SITE_URL}/assets/themes/${theme === "dark" ? "lara-dark-teal" : "lara-light-teal"}/theme.css`;
    document.querySelector("#theme-link").href = themeHref;
  }, [theme]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;
    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    { code: Authority.Home, label: "general.home", icon: "pi-fw pi-home", to: pageURL.home },
    {
      code: Authority.Staff_Management,
      label: "general.staffManagement",
      icon: "pi-users",
      items: [
        { code: Authority.Staff_List, label: "general.employeeList", icon: "pi-id-card", to: pageURL.staffManagement },
        { code: Authority.Title_List, label: "general.titleManagement", icon: "pi-shield", to: pageURL.titleManagement },
      ],
    },
    {
      code: Authority.Company_Management,
      label: "general.companyManagement",
      icon: "pi-building",
      items: [
        { code: Authority.Staff_List, label: "general.companyList", icon: "pi-building", to: pageURL.companyManagement },
        { code: Authority.Customer_List, label: "general.customerList", icon: "pi-users", to: pageURL.customerManagement },
      ],
    },
    {
      code: Authority.Agreement_Management,
      label: "general.agreementManagement",
      icon: "pi-book",
      items: [
        { code: Authority.Agreement_List, label: "general.agreementList", icon: "pi-book", to: pageURL.agreementManagement },
      ],
    },
    {
      code: Authority.Project_Management,
      label: "general.projectManagement",
      icon: "pi-qrcode",
      items: [
        { code: Authority.Project_List, label: "general.projectList", icon: "pi-qrcode", to: pageURL.projectManagement },
        { code: Authority.Team_List, label: "general.teamList", icon: "pi-users", to: pageURL.teamManagement },
        { code: Authority.Sprint_List, label: "general.sprintList", icon: "pi-chart-line", to: pageURL.sprintManagement },
        { code: Authority.Retro_List, label: "general.eventList", icon: "pi-comments", to: pageURL.eventManagement },
      ],
    },
    {
      code: Authority.Progress_Payment,
      label: "general.progressPayment",
      icon: "pi pi-file-check",
      items: [
        { code: Authority.Progress_Payment_List, label: "general.progressPaymentList", icon: "pi-file-plus", to: pageURL.progressPayment },
        { code: Authority.Progress_Payment_Update, label: "general.progressEntries", icon: "pi pi-wallet", to: pageURL.progressPaymentAdd },
        { code: Authority.ExpenseManagement, label: "general.costEntries", icon: "pi-plus-circle", to: pageURL.costEntiries },
        { code: Authority.SalesType_List, label: "general.salesManagement", icon: "pi-chart-line", to: pageURL.salesTypeManagement },
      ],
    },
    {
      code: Authority.Invoice_Management,
      label: "general.invoiceManagement",
      icon: "pi-dollar",
      items: [
        { code: Authority.Invoice_List, label: "general.invoiceManagement", icon: "pi-dollar", to: pageURL.invodeManagement },
        { code: Authority.Kdv_List, label: "general.kdvManagement", icon: "pi-tags", to: pageURL.kdvManagement },
        { code: Authority.Currency_Management, label: "general.currencyManagement", icon: "pi-percentage", to: pageURL.currencyManagement },
        { code: Authority.UserTerm_List, label: "general.userTermManagement", icon: "pi-tag", to: pageURL.userTermManagement },
      ],
    },
    {
      code: Authority.Reports,
      label: "general.reports",
      icon: "pi-inbox",
      items: [
        { code: Authority.Retro_Report, label: "general.retroReports", icon: "pi-bookmark-fill", to: pageURL.retroReport },
        { code: Authority.Sprint_Report, label: "general.sprintReports", icon: "pi-chart-line", to: pageURL.sprintReport },
      ],
    },
    {
      code: Authority.Management,
      label: "general.genralSettings",
      icon: "pi-cog",
      items: [
        { code: Authority.Role_List, label: "general.roleManagement", icon: "pi-sitemap", to: pageURL.roleManagement },
        { code: Authority.Authority_List, label: "general.authorityManagement", icon: "pi-key", to: pageURL.authorityManagement },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  useEffect(() => {
    const filterMenu = menu
      .map((item) => {
        if (item.items && item.items.length > 0) {
          const subItems = [];
          item.items.map((sub) => {
            if (user?.authorityCodes?.includes(sub.code)) {
              subItems.push(sub);
            }
          });

          if (user?.authorityCodes?.includes(item.code)) {
            return { ...item, items: subItems };
          }
        } else {
          if (user?.authorityCodes?.includes(item.code)) {
            return item;
          }
        }
      })
      .filter((item) => typeof item !== "undefined");
    setSideMenu([{ items: filterMenu }]);
  }, [user]);

  return (
    <div className={`${wrapperClass}`} onClick={onWrapperClick}>
      <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

      <div className="layout-sidebar" onClick={onSidebarClick}>
       <AppMenu model={sideMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />

        <div className="version opacity-40 text-xs sticky">
          V {packageJson.version} - {version}
        </div>
      </div>

      <React.Suspense fallback={<Loader isLoaderShow={true}></Loader>}>
        <TheContent />
      </React.Suspense>

      <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
}
