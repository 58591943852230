const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/auth/login`;
export const GENERATE_PASSWORD = `${BASE_URL}/user/generate-password-for-register`;
export const FORGET_PASSWORD = `${BASE_URL}/user/resetpassword`;
export const GET_CUSTOMER_URL = `${BASE_URL}/user/current`;
export const GENERATE_PASSWORD_FORGET = `${BASE_URL}/user/generate-password-for-reset`;
export const CHANGE_PASSWORD = `${BASE_URL}/user/passwordchange`;

//user
export const GET_USERS = `${BASE_URL}/user`;
export const GET_WORKINGTYPES = `${BASE_URL}/user/dropdown-workingtypes`;
export const GET_ENGLISHLEVELS = `${BASE_URL}/user/dropdown-englishlevels`;
export const GET_USERTYPES = `${BASE_URL}/user/dropdown-usertypes`;
export const GET_PERSONALTYPES = `${BASE_URL}/user/dropdown-personaltypes`;
export const GET_WORKINGADRESS = `${BASE_URL}/user/dropdown-workingAdress`;
export const GET_CITY_FOR_DROPDOWN = `${BASE_URL}/user/get-city-for-dropdown`;
export const GET_PROJECT_OWNER_FOR_DROPDOWN = `${BASE_URL}/user/get-ProjectOwner-for-dropdown`;
export const GET_CUSTOMER_FOR_DROPDOWN = `${BASE_URL}/user/get-customer-for-dropdown`;
export const GET_KOLAY_IK = `${BASE_URL}/kolayik`;

export const PERSON_IMAGE_FILE = `${BASE_URL}/storage/user-profile`;
export const TEAM_LOGO = `${BASE_URL}/storage/team-logo`;

export const GET_KOLAYIK = `${BASE_URL}/kolayik`;

export const GET_WORKER_FOR_DROPDOWN = `${BASE_URL}/user/get-worker-for-dropdown`;
export const UPDATE_USER = `${BASE_URL}/user/update`;
export const CREATE_USER = `${BASE_URL}/user/register`;
export const CREATE_CUSTOMER = `${BASE_URL}/customer`;
export const GET_CUSTOMER = `${BASE_URL}/user/get-customers`;
export const GET_AUTHORITYORDERS_DROPDOWN_ITEMS = `${BASE_URL}/user/dropdown-authorityorders`;
export const GET_COMMUNICATIONTYPES_DROPDOWN_ITEMS = `${BASE_URL}/user/dropdown-communicationtypes`;
export const GET_MANAGER_FOR_DROPDOWN = `${BASE_URL}/user/get-menager-for-dropdown`;
export const GET_EXECURIVE_FOR_DROPDOWN = `${BASE_URL}/user/get-executive-for-dropdown`;

//retro
// export const GET_RETRO = `${BASE_URL}/retros`;

//role
export const GET_ACTIVE_ROLE = `${BASE_URL}/role/active-roles-for-dropdown`;
export const GET_CUSTOMER_ROLE = `${BASE_URL}/role/customer-roles-for-dropdown`;
export const GET_ROLES = `${BASE_URL}/role`;

//project
export const GET_PROJECT_UNITS = `${BASE_URL}/project/dropdown-projectunits`;
export const GET_PROJECT_FOR_DROPDOWN = `${BASE_URL}/project/dropdown-projects`;
export const GET_PROJECT = `${BASE_URL}/project`;
export const GET_PROJECT_AGREEMENT_TYPES = `${BASE_URL}/project/dropdown-projectagreementtypes`;
export const GET_PROJECT_ADMINISTRATION_TYPES = `${BASE_URL}/project/dropdown-projectadministrationtypes`;

//title
export const GET_TITLES_FOR_DROPDOWN = `${BASE_URL}/title/get-titles-for-dropdown`;
export const TITLE_LIST_URL = `${BASE_URL}/title`;

//team
export const GET_TEAM = `${BASE_URL}/team`;
export const GET_TEAM_DROPDOWN = `${BASE_URL}/team/dropdown-teams`;
export const GET_TEAM_USERS_BY_TEAMID = `${BASE_URL}/team/get-users-by-teamid`;
export const ADD_USER_TO_TEAM = `${BASE_URL}/team/team-user-add`;
export const UPDATE_USER_TO_TEAM = `${BASE_URL}/team/team-user-update`;
export const DELETE_USER_TO_TEAM = `${BASE_URL}/team/team-user-delete`;
export const GET_USER_IN_TEAM = `${BASE_URL}/team/team-user-detail`;

//company
export const GET_COMPANY_DROPDOWN_ITEMS = `${BASE_URL}/company/dropdown-companies`;
export const COMPANY = `${BASE_URL}/company`;
export const GET_COUNTRY = `${BASE_URL}/company/dropdown-country`;
export const GET_COMPANY_CUSTOMER_TYPE = `${BASE_URL}/company/dropdown-type`;
export const GET_DISTRICT = `${BASE_URL}/company/dropdown-district`;
export const GET_PROJECT_FOR_ACCOUNT_TYPE = `${BASE_URL}/company/dropdown-account-type`;
export const UPDATE_COMPANY = `${BASE_URL}/company`;
export const DISTRICT_URL = `${BASE_URL}/company/getdistrictbycityid`;
export const CITY_URL =`${BASE_URL}/company/dropdown-city`

//authority
export const AUTHORITY_URL = `${BASE_URL}/authority`;
export const AUTHORITY_TREE = `${BASE_URL}/authority/get-authorities-tree`;

//sprint
export const SPRINT_URL = `${BASE_URL}/sprint`;
export const SPRINT_STATUS = `${BASE_URL}/sprint/dropdown-sprintstatusenum`;

//task
export const TASK_URL = `${BASE_URL}/task`;
export const TASK_TYPE_URL = `${BASE_URL}/task/dropdown-tasktype`;
export const TASK_STATUS_URL = `${BASE_URL}/task/dropdown-taskstatus`;

//comment
export const COMMENT_URL = `${BASE_URL}/comment`;

//event (retro)
export const GET_ACTIVITY = `${BASE_URL}/retro/get-activity`;
export const GET_ACTIVITY_TYPE = `${BASE_URL}/retro/dropdown-activitytype`;
export const GET_ACTIVITY_NAME = `${BASE_URL}/retro/dropdown-activityname`;
export const ACTIVITY_URL = `${BASE_URL}/retro`;
export const ACTIVITY_DETAIL_URL = `${BASE_URL}/retro/get-detail`;
export const GET_RETRO = `${BASE_URL}/retro/get-retroreport-bysprintid`;
export const POST_RETRO = `${BASE_URL}/retro/retro-report`;
export const PUT_RETRO = `${BASE_URL}/retro/retroreport-put`;

//invoice
export const GET_INVOICE = `${BASE_URL}/invoice/list`;
export const GET_INVOICE_DETAIL_ID = `${BASE_URL}/invoice`;
export const GET_INVOICE_PERIODS = `${BASE_URL}/invoice/periods`;
export const GET_INVOICE_PERIODS2 = `${BASE_URL}/invoiceperiod/dropdown-invoiceperiods`;
export const GET_INVOICE_ENTRY_INFO = `${BASE_URL}/invoice/entry-info`;
export const GET_INVOICE_USER_PROJECTS = `${BASE_URL}/invoice/dropdown-user-projects`;
export const GET_INVOICE_COMPANY_LOCATION = `${BASE_URL}/invoiceCompany/dropdown-invoicecompanies`;
export const INVOICE_STATUS_UPDATE = `${BASE_URL}/invoice/status-update`;
export const INVOICE_STORAGE = `${BASE_URL}/storage/invoice`;
export const COMPANY_INFO = `${BASE_URL}/invoice/company-info`;
export const GET_COMPANY_DROPDOWN_ITEMS_INVOICE = `${BASE_URL}/invoice/dropdown-companies`;
export const INVOICE_COMPANIES = `${BASE_URL}/invoicecompany/dropdown-invoicecompanies`;
export const INVOICE_PDF_FILE = `${BASE_URL}/invoice/file`;
export const INVOICE_PROJECT_DROPDOWN= `${BASE_URL}/invoice/dropdown-invoice-project`;

//invoice detail
export const INVOICE_DETAIL_USERS = `${BASE_URL}/invoicedetail/users-dropdown`;
export const INVOICE_DETAIL_PROJECTS = `${BASE_URL}/invoicedetail/user-projects-dropdown`;
export const INVOICE_DETAIL_WORK = `${BASE_URL}/invoicedetail/work-type-dropdown`;
export const INVOICE_DETAIL_URL = `${BASE_URL}/invoicedetail`;
export const INVOICE_DETAIL_LIST = `${BASE_URL}/invoicedetail/list`;

//agreement
export const GET_AGREEMENT = `${BASE_URL}/agreement`;
export const GET_PROJECT_AGREEMENTS_TYPE = `${BASE_URL}/agreement/dropdown-for-projectagreementstype`;
export const GET_PROJECT_AGREEMENTSBY_TYPE = `${BASE_URL}/agreement/dropdown-for-projectagreementstype`;
export const GET_AGREEMENT_MAINTENANCE_TYPE = `${BASE_URL}/agreement/dropdown-for-maintenancetype`;
export const GET_CURRENCYTYPE = `${BASE_URL}/agreement/dropdown-for-currencytype`;
export const GET_STAMP_TAX_TYPE = `${BASE_URL}/agreement/dropdown-for-stamptaxtype`;
export const KDV_EXEPTION_TYPE = `${BASE_URL}/agreement/dropdown-for-kdvexemptiontype`;
export const AGREEMENT_PDF_FILE = `${BASE_URL}/storage/agreement`;
export const AGREEMENT_GET_PDF_FILE = `${BASE_URL}/agreement/file`;
export const BILLINGPLAN_URL = `${BASE_URL}/billingplan`;
export const AGREEMENT_TYPE = `${BASE_URL}/agreement/dropdown-for-agreementtype`;

//currency
export const CURRENCY_URL = `${BASE_URL}/currency`;
export const CURRENCY_LIST_URL = `${BASE_URL}/currency/list`;
export const CURRENCY_DROPDOWN = `${BASE_URL}/currency/dropdown`;

//exchangerate
export const EXCHANGE_URL = `${BASE_URL}/exchangerate`;
export const EXCHANGE_DROPDOWN = `${BASE_URL}/exchangerate/dropdown`;
export const EXCHANGE_LIST_URL = `${BASE_URL}/exchangerate/list`;
export const EXCHANGE_STATUS_URL = `${BASE_URL}/exchangerate/status-update`;

//version
export const VERSION_URL = `${BASE_URL}/version`;

//kdv
export const KDV_URL = `${BASE_URL}/kdv`;
export const KDV_STATUS_URL = `${BASE_URL}/kdv/status-update`;
export const KDV_DROPDOWN = `${BASE_URL}/kdv/dropdown`;

//userTerm
export const USERTERM_URL = `${BASE_URL}/userterm`;
export const USERTERM_LIST_URL = `${BASE_URL}/userterm/list`;

//progressPayment
export const GET_USER_URL = `${BASE_URL}/user`;
export const GET_PAYMENT_PERIODS_URL = `${BASE_URL}/progresspayment/periods`;
export const POST_PROGRESS_PAYMENTS_URL = `${BASE_URL}/progresspayment`;
export const GET_PROGRESS_PAYMENTS_DETAIL_URL = `${BASE_URL}/progresspayment/detail`;
export const POST_PROGRESS_PAYMENTS_EXPORT_URL = `${BASE_URL}/progresspayment/excel-export`;
export const POST_PROGRESS_PAYMENTS_IMPORT_URL = `${BASE_URL}/progresspayment/excel-import`;
export const PUT_PROGRESS_PAYMENTS_URL = `${BASE_URL}/progresspayment`;
export const GET_PROGRESS_PAYMENTS_LAST_DETAIL_URL = `${BASE_URL}/progresspayment/last-detail`;
export const GET_PROGRESS_PAYMENTS_GROUPED_LIST = `${BASE_URL}/progresspayment/list`;
export const GET_PROGRESS_PAYMENTS_MANAGER_FOR_DROPDOWN = `${BASE_URL}/progresspayment/get-menager-for-dropdown`;
export const GET_PROGRES_WORKING_TYPE = `${BASE_URL}/progresspayment/get-working-time-type-for-dropdown` ;

//Cost Entiries
export const GET_COST_ENTIRIES_URL = `${BASE_URL}/expense/get`;
export const POST_COST_ENTIRIES_URL = `${BASE_URL}/expense`;
export const PUT_COST_ENTIRIES_URL = `${BASE_URL}/expense`;
export const GET_COST_ENTIRIES_BY_ID_URL = `${BASE_URL}/expense`;
export const DELETE_COST_ENTIRIES_URL = `${BASE_URL}/expense`;
export const GET_COST_ENTIRIES_PERIODS_URL = `${BASE_URL}/expense/periods`;
export const EXPENSE_TYPE_URL = `${BASE_URL}/expense/dropdown-expense-type`;


//Sales Type
export const SALES_TYPE = `${BASE_URL}/sales`;
export const SALES_TYPE_PERIOD = `${BASE_URL}/sales/dropdown-invoice-period`;
export const GET_TYPE = `${BASE_URL}/sales/dropdown-type`
export const LICANCE_TYPE = `${BASE_URL}/sales/dropdown-licance-type`
export const GET_PRODUCT = `${BASE_URL}/sales/dropdown-product`
export const GET_INVOICE_PERIOD = `${BASE_URL}/sales/dropdown-invoice-period`
export const UPDATE_STATUS = `${BASE_URL}/sales/status-update`
export const SALES_TYPE_LIST =`${BASE_URL}/sales/list`









