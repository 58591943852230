import React from "react";
import PrimeReact from "primereact/api";
import { Switch } from "react-router";

import 'reactjs-popup/dist/index.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import 'reactjs-popup/dist/index.css';
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";

//Context
import { LoadingContextProvider } from "./context/Loading";
import { ToasterContextProvider } from "./context/ToasterContext";
import { UserContextProvider } from "./context/UserContext";
import { AppContextProvider } from "./context/AppContext";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import pageURL from "./utils/pageUrls";

//Pages
import TheLayout from "./template/TheLayout";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import GeneratePassword from "./pages/GeneratePassword";
import ChangePasswordForget from "./pages/ChangePasswordForget";


const App = () => {
  PrimeReact.ripple = true;

  return (
    <LoadingContextProvider>
      <AppContextProvider>
        <ToasterContextProvider>
          <UserContextProvider>
              <Switch>
                <PublicRoute component={Login} path={pageURL.login} exact />
                <PublicRoute component={ForgetPassword} path={pageURL.forgetPassword} exact />
                <PublicRoute component={GeneratePassword} path={pageURL.generatePassword} exact />
                <PublicRoute component={ChangePasswordForget} path={pageURL.changePasswordForget} exact />
                <PrivateRoute component={TheLayout} path="*" exact />
              </Switch>
          </UserContextProvider>
        </ToasterContextProvider>
      </AppContextProvider>
    </LoadingContextProvider>
  );
};

export default App;
