/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { UserContext } from "../context/UserContext";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomForm from "../components/CustomForm";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { ToasterContext } from "../context/ToasterContext";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
  const { login } = useContext(UserContext);
  const { t } = useTranslation();
  const { openToaster } = useContext(ToasterContext);
  const [wrongLoginCount, setWrongLoginCount] = useState(0)


  useEffect(() => {
    const status = new URLSearchParams(window.location.search).get("status");
    const messages = {
      "401": t("general.loginError"),
      "403": t("general.hasNotPanelAcccess"),
      "404": t("general.pageNotFound")
    };

    if (status && messages[status]) {
      openToaster("bottom-right", { severity: "warn", summary: t("general.warning"), detail: messages[status], life: 3000 });
    }
  }, []);

  const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const validationSchema = Yup.object({
    email: Yup.string().required(t("validation.required", "Zorunlu Alan")).email(t("validation.mailInvalid")).matches(mailRegex, t("validation.mailInvalid")),
    password: Yup.string().required(t("validation.required", "Zorunlu Alan")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      await login(data);
      formik.resetForm();
    },
  });

  const formItems = [
    {
      label: t("general.email"),
      name: "email",
      component: "input",
    },
    {
      label: t("general.password"),
      name: "password",
      component: "password",
    },
  ];


  const containerClassName = classNames("surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden login-page");

  return (
    <div className={containerClassName}>
      <div className="flex flex-column align-items-center justify-content-center">
        <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
          <div className="w-full surface-card py-8 px-5 sm:px-8 " style={{ borderRadius: "53px" }}>
            <img src={"/images/experilabsDark.svg"} alt="exp logo" className="mb-5 w-20rem flex-shrink-0 " />
            <div>
              <form onSubmit={formik.handleSubmit}>
                <CustomForm items={formItems} data={formik} handleChange={formik.handleChange} isColumn />
                <div className="col-12 text-right mb-2">
                  <Link to="/forget-password" className="p-button-link p-0">
                    {t("general.getNewPassword")}
                  </Link>
                </div>
                {/* <ReCAPTCHA
                  sitekey="6Ld6nmgpAAAAALB19Zf-BQ8vpq4zh-tIeYgJckAp"
                  onChange={handleRecaptchaChange}
                /> */}
                <Button label={t("general.login")} type="submit" className="w-full"  onClick={() => setWrongLoginCount(wrongLoginCount + 1)} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
