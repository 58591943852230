import React, { useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Menu } from "primereact/menu";
import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router";
import pageURL from "../utils/pageUrls";
import { ToggleButton } from "primereact/togglebutton";
import { AppContext } from "../context/AppContext";
import { Dropdown } from "primereact/dropdown";
import { locale } from "primereact/api";

const languages = [
  { name: "Türkçe", value: "tr" },
  { name: "English", value: "en" },
];

export const AppTopbar = (props) => {
  const { push } = useHistory();
  const { t, i18n } = useTranslation();
  const { logout } = useContext(UserContext);
  const { theme, actions } = useContext(AppContext);
  const menu = useRef(null);

  const toggleLang = (lng) => {
    i18n.changeLanguage(lng);
    locale(lng);
  };

  const items = [
    {
      label: t("general.changePassword"),
      icon: "pi pi-unlock",
      command: () => {
        push(pageURL.changePassword);
      },
    },
    {
      label: t("general.logout"),
      icon: "pi pi-sign-out",
      command: () => {
        logout();
      },
    },
  ];

  // const handleThemeMode = (e) => {
  //   actions.changeTheme(e.value ? "dark" : "light");
  // };
  return (
    <div className="layout-topbar">
      <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
        <i className="pi pi-bars" />
      </button>
      <Link to="/" className="layout-topbar-logo flex justify-content-center">
        <img className="w-10" src={theme === "light" ? "/images/experilabsDark.svg" : "/images/experilabsWhite.svg"} alt="Experilabs Logo" />
        {/* buradki  theme  kontrolünü  sağlayan    fonksiyon 65 ve 48. satırda  açıklama  satırına alınan koddur buradan  kaldırılmamasının  sebebi  ileride theme in  düzenlenmesidir  şuanlık dark mode  kullanılmayacaktır Alptuğ Ulus    */}
      </Link>
      <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
        <i className="pi pi-ellipsis-v" />
      </button>
      <ul className={classNames("layout-topbar-menu lg:flex origin-top align-items-center", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
        <li className="flex align-items-center">
          {/* <ToggleButton className="mr-2" checked={theme === "dark" ? true : false} onChange={(e) => handleThemeMode(e)} onIcon="pi pi-moon" offIcon="pi pi-sun" onLabel="" offLabel="" /> */}
        </li>
        <li className="flex align-items-center mr-2">
          <Dropdown value={i18n?.language} options={languages} onChange={(e) => toggleLang(e.value)} optionLabel="name" optionValue="value" />
        </li>
        <li>
          <button className="layout-topbar-button" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup>
            <i className="pi pi-user" />
            <span>{t("general.profile")}</span>
          </button>
          <Menu model={items} popup ref={menu} id="popup_menu" />
        </li>
      </ul>
    </div>
  );
};
