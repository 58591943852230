import React from "react";
import pageURL from "./utils/pageUrls";

const Home = React.lazy(() => import("./pages/Home"));
const forgetPassword = React.lazy(() => import("./pages/ForgetPassword"));
const generatePassword = React.lazy(() => import("./pages/GeneratePassword"));
const changePassword = React.lazy(() => import("./pages/ChangePassword"));

const SprintManagement = React.lazy(() => import("./pages/SprintManagement/index"));
const SprintManagementAction = React.lazy(() => import("./pages/SprintManagement/Action"));

const ProjectManagement = React.lazy(() => import("./pages/ProjectManagement/index"));
const ProjectManagementAction = React.lazy(() => import("./pages/ProjectManagement/Action"));

const StaffManagement = React.lazy(() => import("./pages/StaffManagement/index"));
const StaffManagementAction = React.lazy(() => import("./pages/StaffManagement/Action"));

const CustomerManagement = React.lazy(() => import("./pages/CustomerManagement/index"));
const CustomerManagementAction = React.lazy(() => import("./pages/CustomerManagement/Action"));

const TeamManagement = React.lazy(() => import("./pages/TeamManagement/index"));
const TeamManagementAction = React.lazy(() => import("./pages/TeamManagement/Action"));

const AuthorityManagement = React.lazy(() => import("./pages/AuthorityManagement/index"));

const RoleManagement = React.lazy(() => import("./pages/RoleManagement/index"));
const RoleManagementAction = React.lazy(() => import("./pages/RoleManagement/Action"));

const TitleManagement = React.lazy(() => import("./pages/TitleManagement/index"));

const SprintReport = React.lazy(() => import("./pages/SprintReport/index"));
const SprintReportDetail = React.lazy(() => import("./pages/SprintReport/Action"));

const EventManagement = React.lazy(() => import("./pages/EventManagement/index"));
const EventManagementAction = React.lazy(() => import("./pages/EventManagement/Action"));

const RetroReport = React.lazy(() => import("./pages/RetroReport/index"));
const RetroReportDetail = React.lazy(() => import("./pages/RetroReport/Action"));

const CompanyManagement = React.lazy(() => import("./pages/CompanyManagement/index"));
const CompanyManagementAction = React.lazy(() => import("./pages/CompanyManagement/Action"));

const CurrencyManagement = React.lazy(() => import("./pages/CurrencyManagement/index"));
const CurrencyManagementAction = React.lazy(() => import("./pages/CurrencyManagement/Action"));

const UserTermManagement = React.lazy(() => import("./pages/UserTermManagement/index"));
const UserTermManagementAction = React.lazy(() => import("./pages/UserTermManagement/Action"));

const KdvManagement = React.lazy(() => import("./pages/KdvManagement/index"));
const KdvManagementAction = React.lazy(() => import("./pages/KdvManagement/Action"));

const AgreementManagement = React.lazy(() => import("./pages/AgreementManagement/index"));
const AgreementManagementAction = React.lazy(() => import("./pages/AgreementManagement/Action"));

const SalesTypeManagement = React.lazy(() => import("./pages/SalesType/index"));
const SalesTypeManagementAction = React.lazy(() => import("./pages/SalesType/Action"));


const ProgressPayment = React.lazy(() => import("./pages/ProgressPayment/index"));
const ProgressPaymentDetail = React.lazy(() => import("./pages/ProgressPayment/Action"));

const InvoiceManagement = React.lazy(() => import("./pages/InvoiceManagement/index"));
const InvoiceManagementDetail = React.lazy(() => import("./pages/InvoiceManagement/Action"));
const InvoiceDetailManagement = React.lazy(() => import("./pages/InvoiceManagement/detail"));

const CostEntiries = React.lazy(() => import("./pages/CostEntiries/index"));
const AddCostEntiry = React.lazy(() => import("./pages/CostEntiries/Action"));

const routes = [
  { path: pageURL.home, exact: true, name: "general.home", component: Home },
  { path: pageURL.forgetPassword, exact: true, name: "general.getNewPassword", component: forgetPassword },
  { path: pageURL.generatePassword, exact: true, name: "general.generatePassword", component: generatePassword },
  { path: pageURL.changePassword, exact: true, name: "general.changePassword", component: changePassword },

  { path: pageURL.sprintManagement, exact: true, name: "general.sprintManagement", component: SprintManagement },
  { path: pageURL.sprintManagementAction, exact: true, name: "general.sprintManagement", component: SprintManagementAction },

  { path: pageURL.projectManagement, exact: true, name: "general.projectManagement", component: ProjectManagement },
  { path: pageURL.projectManagementAction, exact: true, name: "general.projectManagement", component: ProjectManagementAction },

  { path: pageURL.staffManagement, exact: true, name: "general.staffManagement", component: StaffManagement },
  { path: pageURL.staffManagementAction, exact: true, name: "general.staffManagement", component: StaffManagementAction },

  { path: pageURL.customerManagement, exact: true, name: "general.customerManagement", component: CustomerManagement },
  { path: pageURL.customerManagementAction, exact: true, name: "general.customerManagement", component: CustomerManagementAction },

  { path: pageURL.teamManagement, exact: true, name: "general.teamManagement", component: TeamManagement },
  { path: pageURL.teamManagementAction, exact: true, name: "general.teamManagement", component: TeamManagementAction },

  { path: pageURL.authorityManagement, exact: true, name: "general.authorityManagement", component: AuthorityManagement },

  { path: pageURL.roleManagement, exact: true, name: "general.roleManagement", component: RoleManagement },
  { path: pageURL.roleManagementAction, exact: true, name: "general.roleManagement", component: RoleManagementAction },

  { path: pageURL.titleManagement, exact: true, name: "general.titleManagement", component: TitleManagement },

  { path: pageURL.sprintReport, exact: true, name: "general.sprintReports", component: SprintReport },
  { path: pageURL.sprintReportDetail, exact: true, name: "general.sprintReports", component: SprintReportDetail },

  { path: pageURL.eventManagement, exact: true, name: "general.eventManagement", component: EventManagement },
  { path: pageURL.eventManagementAction, exact: true, name: "general.eventManagement", component: EventManagementAction },

  { path: pageURL.retroReport, exact: true, name: "general.retroReports", component: RetroReport },
  { path: pageURL.retroReportDetail, exact: true, name: "general.retroReports", component: RetroReportDetail },

  { path: pageURL.companyManagement, exact: true, name: "general.companyManagement", component: CompanyManagement },
  { path: pageURL.companyManagementAction, exact: true, name: "general.companyManagement", component: CompanyManagementAction },

  { path: pageURL.currencyManagement, exact: true, name: "general.currencyManagement", component: CurrencyManagement },
  { path: pageURL.currencyManagementAction, exact: true, name: "general.currencyManagement", component: CurrencyManagementAction },

  { path: pageURL.userTermManagement, exact: true, name: "general.userTermManagement", component: UserTermManagement },
  { path: pageURL.userTermManagementAction, exact: true, name: "general.userTermManagement", component: UserTermManagementAction },

  { path: pageURL.kdvManagement, exact: true, name: "general.kdvManagement", component: KdvManagement },
  { path: pageURL.kdvManagementAction, exact: true, name: "general.kdvManagement", component: KdvManagementAction },

  { path: pageURL.agreementManagement, exact: true, name: "general.agreementManagement", component: AgreementManagement },
  { path: pageURL.agreementManagementAction, exact: true, name: "general.agreementManagement", component: AgreementManagementAction },

  { path: pageURL.salesTypeManagement, exact: true, name: "general.salesManagement", component: SalesTypeManagement },
  { path: pageURL.salesTypeManagementAction, exact: true, name: "general.salesManagement", component: SalesTypeManagementAction },
  { path: pageURL.salesTypeManagementEdit, exact: true, name: "general.salesManagement", component: SalesTypeManagementAction },


  { path: pageURL.invodeManagement, exact: true, name: "general.invoiceManagement", component: InvoiceManagement },
  { path: pageURL.invodeManagementDetails, exact: true, name: "general.invoiceManagement", component: InvoiceManagementDetail },
  { path: pageURL.invoiceDetailManagement, exact: true, name: "general.invoiceDetailManagement", component: InvoiceDetailManagement },

  { path: pageURL.progressPayment, exact: true, name: "general.progressPayment", component: ProgressPayment },
  { path: pageURL.progressPaymentAdd, exact: true, name: "general.progressEntries", component: ProgressPayment },
  { path: pageURL.progressPaymentDetail, exact: true, name: "general.progressPaymentDetail", component: ProgressPaymentDetail },

  { path: pageURL.costEntiries, exact: true, name: "general.costEntries", component: CostEntiries },
  { path: pageURL.addCostEntiries, exact: true, name: "general.costEntries", component: AddCostEntiry },
];


export default routes;
