const pageURL = {
  home: "/",
  login: "/login",
  forgetPassword: "/forget-password",
  generatePassword: "/generate",
  changePasswordForget: "/change-password-forget",
  changePassword: "/change-password",

  sprintManagement: "/sprint-management",
  sprintManagementAction: "/sprint-management/:mode/:id?",

  projectManagement: "/project-management",
  projectManagementAction: "/project-management/:mode/:id?",

  staffManagement: "/staff-management",
  staffManagementAction: "/staff-management/:mode/:id?",

  customerManagement: "/customer-management",
  customerManagementAction: "/customer-management/:mode/:id?",

  teamManagement: "/team-management",
  teamManagementAction: "/team-management/:mode/:id?",

  authorityManagement: "/authority-management",

  roleManagement: "/role-management",
  roleManagementAction: "/role-management/:mode/:id?",

  titleManagement: "/title-management",

  sprintReport: "/sprint-report",
  sprintReportDetail: "/sprint-report/:mode/:id",

  eventManagement: "/event-management",
  eventManagementAction: "/event-management/:mode/:id?",

  retroReport: "/retro-report",
  retroReportDetail: "/retro-report/:mode/:id?",

  companyManagement: "/company-management",
  companyManagementAction: "/company-management/:mode/:id?",

  currencyManagement: "/currency-management",
  currencyManagementAction: "/currency-management/:mode/:id?",

  userTermManagement: "/userTerm-management",
  userTermManagementAction: "/userTerm-management/:mode/:id?",

  kdvManagement: "/kdv-management",
  kdvManagementAction: "/kdv-management/:mode/:id?",

  agreementManagement: "/agreement-management",
  agreementManagementAction: "/agreement-management/:mode/:id?",

  progressPayment: "/progress-payment-management",
  progressPaymentDetail: "/progress-payment-management/:mode/:userId/:period",
  progressPaymentAdd: "/progress-payment-management/insert",

  invodeManagement: "/invoice-management",
  invodeManagementDetails: "/invoice-management/:mode/:invoiceId?",
  invodeManagementAdd: "/invoice-managemen/:mode",
  invoiceDetailManagement: "/invoice-managemen/invoice/detail/:id?",
  costEntiries: "/cost-entries",
  addCostEntiries: "/cost-entries/:mode/:id?",

  salesTypeManagement:"/sales-type",
  salesTypeManagementAction:"/sales-type/:mode/:id?",
  salesTypeManagementEdit:"/sales-types/:mode/:id?"
};

export default pageURL;
